<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label='姓名'>
            <el-input v-model.trim="queryForm.usernameLike" clearable placeholder="请输入用户名" />
          </el-form-item>
          <el-form-item label='手机号'>
            <el-input v-model.trim="queryForm.phone" clearable placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label='备注'>
            <el-input v-model.trim="queryForm.remark" clearable placeholder="请输入备注" />
          </el-form-item>
          <el-form-item label='入池时间'>
            <el-date-picker v-model="queryForm.time" type="datetimerange" :shortcuts="shortcuts" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" clearable @clear="clearDateTime" />
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
      <!-- <vab-query-form-right-panel :span="24">

      </vab-query-form-right-panel> -->
    </vab-query-form>

    <vab-query-form>
      <vab-query-form-left-panel :span="24">
        <el-form :model="queryForm" @submit.prevent :label-position="labelPosition">
          <el-form-item label='用户类别' style="margin: 0  0 10px 0!important;" label-width='100px'>
            <ul class="radioBox">
              <li @click="selectChange('userType', item.key)" :class="[queryForm.userType ==  item.key ? 'isChecked' : '']" v-for='(item, index) in userTypeList' :key="index">{{item.name}}</li>
            </ul>
          </el-form-item>
          <el-form-item label='关注公众号' style="margin: 0  0 10px 0!important;" label-width='100px'>
            <ul class="radioBox">
              <li @click="selectChange('officalCount', item.key)" :class="[queryForm.followWeChat ==  item.key ? 'isChecked' : '']" v-for='(item, index) in officalCountList' :key="index">{{item.name}}</li>
            </ul>
          </el-form-item>
          <el-form-item label='用户来源' style="margin: 0  0 10px 0!important;" label-width='100px'>
            <ul class="radioBox">
              <li @click="selectChange('userSource', item.type)" :class="[queryForm.userSource ==  item.type ? 'isChecked' : '']" v-for='(item, index) in userSourceList' :key="index">{{item.name}}</li>
            </ul>
          </el-form-item>
          <el-form-item :label='index == 0 ? "所属地区" : ""' style="margin: 0  0 10px 0!important;" label-width='100px' v-for="(item, index) in areaBo" :key="index">
            <!-- 多选地区 -->
            <el-select style="margin-right:10px;" :clearable="index == 0 ? true : false" filterable v-model="item.area" placeholder="请选择地区" @clear="clearCountry(item)">
              <el-option v-for="(item, index) in counrtyList" :key="item.countryCode" :label="item.country" :value="item.countryCode"></el-option>
            </el-select>
            <el-cascader :props="optionsLevel" v-if="item.area == 'CN'" v-model="item.areaList" style="width: 250px" clearable />
            <el-button @click="handleAddAreaChoose" v-if="index == 0" :icon="Plus"></el-button>
            <el-button :icon="Minus" type="danger" v-if="index != 0" @click="removeAreaChoose(index)"></el-button>
          </el-form-item>

          <el-form-item :label='index == 0 ? "参加展会" : ""' style="margin: 0  0 10px 0!important;" label-width='100px' v-for="(bitem, index) in exhBo" :key="index">
            <el-select style="margin-right:10px;" :clearable="index == 0 ? true : false" filterable v-model="bitem.code" placeholder="输入展会名称查询" @clear="clearExhibition(bitem)">
              <el-option v-for="(item, index) in exhList" :key="item.code" :label="item.meetingName" :value="item.code"></el-option>
            </el-select>
            <el-button @click="handleAddExhChoose" v-if="index == 0" :icon="Plus"></el-button>
            <el-button :icon="Minus" type="danger" v-if="index != 0" @click="removeExhChoose(index)"></el-button>
            <div v-if="exhBo.length >= 2 && index == 0" style="margin-left:10px;">
              <span>筛选条件:</span>
              <el-select style="margin-left:10px;" v-model="queryForm.selectCondition" placeholder="请选择条件">
                <el-option label="都报过名" value="AND"></el-option>
                <el-option label="报名过其中一个的" value="OR"></el-option>
              </el-select>
            </div>

          </el-form-item>

          <el-form-item style="margin: 0  0 0px 0!important;">
            <el-button type="primary" @click="clearReset($event)">
              置空
            </el-button>
            <el-button type="primary" @click="searchData($event)">
              查询
            </el-button>
            <el-button type="primary" @click="visitorImport($event)">
              导入用户
            </el-button>
            <el-button type="primary" @click="exportResult($event)">
              导入结果
            </el-button>
            <el-button type="primary" @click="importWechat($event)">
              同步微信公众号粉丝
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" fixed label="头像" prop="username" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image style="width: 50px; height: 50px" :src="row.avatar ? row.avatar : `https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/images/new-default-avatar.png`" :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" :initial-index="4" fit="cover" />
        </template>
      </el-table-column>
      <el-table-column align="center" label="姓名" prop="realName" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.realName ? row.realName : '/' }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="手机号" prop="phone" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.phone ? row.phoneCountryCode + ' ' + row.phone : '/' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="邮箱" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.email ? row.email : '/' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="实名身份" show-overflow-tooltip>
        <template #default="{ row }">
          {{ filterStatus[row.userType] }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="公司/机构" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.company ? row.company : '/' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="职位" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.position ? row.position : '/' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.remark ? row.remark : '/' }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="地区" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.country ? row.country : '/' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="入池时间" prop="createTime" show-overflow-tooltip />
      <el-table-column align="center" fixed="right" label="操作" show-overflow-tooltip>
        <template #default="{ row }">
          <el-button @click="handleEdit(row)" type="text">查看</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.page" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit ref="editRef" @fetch-data="fetchData" />

    <upload-file ref="upLoadRef"  @fetch-data="fetchData"/>

    <upload-result ref="resultRef" />

    <ImportWeChat ref="wechatRef"></ImportWeChat>

  </div>

</template>

<script>
import {
  defineAsyncComponent,
  defineComponent,
  getCurrentInstance,
  onActivated,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { deleteUser, getList, distributionShop } from '@/api/userManagement'
import { getProvinceInfo, getCityInfo, getAreaInfo } from '@/api/shop'
import { countryQueryInfo } from '@/api/brand'
import {
  enterPriseUserQueryUserSource,
  enterPriseUserQueryUser,
  enterPriseUserQueryWechatConfig,
} from '@/api/enterPriseApi'
import { exShelfAndUnshelfList } from '@/api/exhibitionManage'
import { Plus, Minus } from '@element-plus/icons'
import ImportWeChat from './components/importWeChat.vue'
import { parseTime } from '@/utils/index'
export default defineComponent({
  name: 'UserManage',
  components: {
    Edit: defineAsyncComponent(() => import('./components/userDetail.vue')),
    uploadFile: defineAsyncComponent(() =>
      import('./components/visitorImport')
    ),
    uploadResult: defineAsyncComponent(() =>
      import('./components/uploadResult')
    ),
    ImportWeChat,
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      shortcuts: [
        {
          text: '上周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setDate(start.getDate() - 7)
            return [start, end]
          },
        },
        {
          text: '上个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 1)
            return [start, end]
          },
        },
        {
          text: '前三个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 3)
            return [start, end]
          },
        },
      ],
      labelPosition: `left`,
      editRef: null,
      list: [],
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      selectRows: '',
      showShop: false,
      counrtyList: [],
      props: {
        checkStrictly: true,
      },
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        endTime: '',
        followWeChat: 'ALL',
        name: '',
        phone: '',
        queryAddress: [],
        queryEnterpriseUserMeetingBos: {
          logic: '',
          meetingCode: [],
        },
        selectCondition: 'AND',
        startTime: '',
        userSource: 'ALL',
        userType: 'ALL',
        time: [],
      },
      filterStatus: {
        WEMEET_USER: '已注册未实名',
        UNREGISTERED: '未注册',
        WEMEET_USER_REAL_NAME: '已注册已实名',
      },
      userTypeList: [
        {
          name: '全部',
          key: 'ALL',
        },
        {
          name: '未注册',
          key: 'UNREGISTERED',
        },
        {
          name: '已注册未实名',
          key: 'WEMEET_USER',
        },
        {
          name: '已注册已实名',
          key: 'WEMEET_USER_REAL_NAME',
        },
      ],
      officalCountList: [
        {
          name: '全部',
          key: 'ALL',
        },
        {
          name: '已关注',
          key: 'ATTENTION',
        },
        {
          name: '未知',
          key: 'NO_ATTENTION',
        },
      ],
      userSourceList: [],
      areaBo: [
        {
          area: '',
          areaList: [],
        },
      ],
      exhBo: [
        {
          code: '',
        },
      ],
      queryFormBig: {
        pageNum: 1,
        pageSize: 999,
      },
      exhList: [],

      upLoadRef: null,
      resultRef: null,
      wechatRef: null,
    })

    const setSelectRows = (val) => {
      state.selectRows = val
    }
    const handleEdit = (row) => {
      console.log(row)
      if (row.uid) {
        state['editRef'].handleOpen(row)
      }
    }
    const handleDelete = (row) => {
      if (row.uid) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await deleteUser(row.uid)
          proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }
    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      fetchData()
    }
    const queryData = () => {
      state.queryForm.page = 1
      fetchData()
    }
    const fetchData = async () => {
      state.listLoading = true
      try {
        const { data } = await enterPriseUserQueryUser(state.queryForm)
        state.list = data.list
        state.total = data.total
        state.listLoading = false
      } catch (err) {
        state.listLoading = false
      }
    }

    const initData = async () => {
      // 查询所有国家列表
      const { data: country } = await countryQueryInfo()
      state.counrtyList = country

      // 查询企业用户来源
      const { data: sourceList } = await enterPriseUserQueryUserSource()
      sourceList.unshift({ type: 'ALL', name: '全部' })
      state.userSourceList = sourceList

      // 查询机构下所有展会

      const { data: exhList } = await exShelfAndUnshelfList(state.queryFormBig)
      state.exhList = exhList.data
    }
    onActivated(() => {
      // 获取当天开始时间
      const now = new Date()
      const startOfDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      )
      const endOfDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,
        59,
        59,
        999
      )

      state.queryForm.time = [startOfDay, endOfDay]
      state.queryForm.startTime = parseTime(
        startOfDay,
        '{y}-{m}-{d} {h}:{i}:{s}'
      )
      state.queryForm.endTime = parseTime(endOfDay, '{y}-{m}-{d} {h}:{i}:{s}')
      fetchData()
      initData()
    })

    const selectChange = (type, key) => {
      if (type == 'userType') {
        state.queryForm.userType = key
      }
      if (type == 'officalCount') {
        state.queryForm.followWeChat = key
      }
      if (type == 'userSource') {
        state.queryForm.userSource = key
      }

      queryData()
    }

    const optionsLevel = {
      lazy: true,
      value: 'code',
      label: 'name',
      checkStrictly: true,
      async lazyLoad(node, resolve) {
        const { level } = node
        const { code } = node.data
        if (level == 0) {
          const { data } = await getProvinceInfo()
          resolve(data)
        } else if (level == 1) {
          const { data } = await getCityInfo({ code: code })
          resolve(data)
        } else {
          const { data } = await getAreaInfo({ code: code })
          data.forEach((v, i) => {
            v.leaf = level >= 2
          })
          resolve(data)
        }
      },
    }

    const handleAddAreaChoose = () => {
      if (state.areaBo.length >= 5) {
        proxy.$baseMessage('最多只能添加 5 个筛选条件', 'error')
        return false
      }
      if (state.areaBo[state.areaBo.length - 1].area == '') {
        proxy.$baseMessage('请选择地区', 'error')
        return false
      }
      state.areaBo.push({
        area: '',
        areaList: [],
      })
    }

    const removeAreaChoose = (index) => {
      state.areaBo.splice(index, 1)
    }

    const handleAddExhChoose = () => {
      if (state.exhBo.length >= 5) {
        proxy.$baseMessage('最多只能添加 5 个筛选条件', 'error')
        return false
      }
      if (state.exhBo[state.exhBo.length - 1].code == '') {
        proxy.$baseMessage('请选择展会', 'error')
        return false
      }
      state.exhBo.push({ code: '' })
    }

    const removeExhChoose = (index) => {
      state.exhBo.splice(index, 1)
    }

    const searchData = () => {
      // if (state.areaBo[0].area !== '') {
      state.queryForm.queryAddress = []
      state.areaBo.forEach((item) => {
        let obj = {}
        obj.countryCode = item.area
        if (item.area == 'CN') {
          obj.provinceCode = item.areaList[0] ? item.areaList[0] : ''
          obj.cityCode = item.areaList[1] ? item.areaList[1] : ''
          obj.areaCode = item.areaList[2] ? item.areaList[2] : ''
        }

        if (obj.countryCode != '') {
          state.queryForm.queryAddress.push(obj)
        }
      })
      if (state.exhBo[0].code != '') {
        state.queryForm.queryEnterpriseUserMeetingBos = {
          logic: '',
          meetingCode: [],
        }
        state.exhBo.forEach((item) => {
          if (item.code != '') {
            state.queryForm.queryEnterpriseUserMeetingBos.meetingCode.push(
              item.code
            )
          }
        })
        if (state.exhBo.length >= 2) {
          state.queryForm.queryEnterpriseUserMeetingBos.logic =
            state.queryForm.selectCondition
        } else {
          state.queryForm.queryEnterpriseUserMeetingBos.logic = 'AND'
        }
      } else {
        state.queryForm.queryEnterpriseUserMeetingBos.logic = ''
        state.queryForm.queryEnterpriseUserMeetingBos.meetingCode = []
      }

      state.queryForm.pageNum = 1
      console.log(state.queryForm.time, '====>>>>')
      if (state.queryForm.time && state.queryForm.time.length > 0) {
        state.queryForm.startTime = state.queryForm.time[0]
          ? parseTime(state.queryForm.time[0], '{y}-{m}-{d} {h}:{i}:{s}')
          : ''
        state.queryForm.endTime = state.queryForm.time[1]
          ? parseTime(state.queryForm.time[1], '{y}-{m}-{d} {h}:{i}:{s}')
          : ''
      } else {
        state.queryForm.startTime = ''
        state.queryForm.endTime = ''
      }

      console.log(state.queryForm, state.areaBo, state.exhBo)
      fetchData()
    }

    const clearReset = () => {
      state.queryForm = {
        pageNum: 1,
        pageSize: 10,
        endTime: '',
        followWeChat: 'ALL',
        name: '',
        phone: '',
        queryAddress: [],
        queryEnterpriseUserMeetingBos: {
          logic: '',
          meetingCode: [],
        },
        startTime: '',
        userSource: 'ALL',
        userType: 'ALL',
        selectCondition: 'AND',
      }
      state.areaBo = [
        {
          area: '',
          areaList: [],
        },
      ]
      state.exhBo = [
        {
          code: '',
        },
      ]
      fetchData()
    }

    // 观众根据渠道导入
    const visitorImport = (row) => {
      state.upLoadRef.showEdit()
    }

    const exportResult = (row) => {
      state.resultRef.showEdit()
    }

    const importWechat = async () => {
      const { data } = await enterPriseUserQueryWechatConfig()
      console.log(data)
      state.wechatRef.showEdit(data)
    }

    const clearCountry = (row) => {
      console.log(row)
      row.areaList = []
    }

    const clearExhibition = (row) => {
      console.log(row)
      row.code = ''
    }

    const clearDateTime = () => {}

    return {
      ...toRefs(state),
      setSelectRows,
      handleEdit,
      handleDelete,
      handleSizeChange,
      handleCurrentChange,
      queryData,
      fetchData,
      selectChange,
      optionsLevel,
      handleAddAreaChoose,
      Plus,
      Minus,
      removeAreaChoose,
      handleAddExhChoose,
      removeExhChoose,
      searchData,
      clearReset,
      visitorImport,
      exportResult,
      importWechat,
      clearCountry,
      clearExhibition,
      clearDateTime,
    }
  },
})
</script>


<style lang="scss" scoped>
.radioBox {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
  li {
    list-style: none;
    margin-right: 20px;
    cursor: pointer;
    &.isChecked {
      color: #00c4c9;
    }
  }
}
</style>